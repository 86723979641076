<template>
  <div class="wallet-account-currency">
    <div class="container-fluid mt--5">
      <!-- <div class="card mb-3">
        <div class="card-header">
          <h3 class="mb-0">My Wallet {{ currency }}</h3>
        </div>
        <div class="card-body" style="min-height: 200px">
          <div class="row"></div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-xl-4 mb-4 mb-xl-0">
          <div class="card h-100">
            <div class="card-header pb-0 p-3">
              <h4 class="mb-0">Menu</h4>
            </div>

            <div class="card-body p-3 pb-4">
              <div
                class="d-flex justify-content-between pb-3"
              >
                <div
                v-if="accessRole.payment" 
                  class="d-flex align-items-center card p-2 cursor-pointer"
                  @click="
                    $router.push({
                      path: '/wallet/send',
                      query: { from: walletAccount.currency.toUpperCase() },
                    })
                  "
                >
                  <svg-icon icon-class="send" style="vertical-align: middle" />
                  <span class="nav-link-text">Send</span>
                </div>
                <div 
                v-if="accessRole.payment" 
                class="d-flex align-items-center card p-2 cursor-pointer"
                @click="
                    $router.push({
                      path: '/wallet/deposit',
                      query: { to: walletAccount.currency.toUpperCase() },
                    })
                  "
                >
                  <svg-icon
                    icon-class="deposit"
                    style="vertical-align: middle"
                  />
                  <span class="nav-link-text">Deposit</span>
                </div>
                <div
                v-if="accessRole.autofx" 
                class="d-flex align-items-center card p-2 cursor-pointer"
                @click="
                    $router.push({
                      path: '/wallet/fx',
                      query: { from: walletAccount.currency.toUpperCase() },
                    })
                  "
                >
                  <svg-icon
                    icon-class="auto-fx"
                    style="vertical-align: middle"
                  />
                  <span class="nav-link-text">FX</span>
                </div>
                <div 
                v-if="accessRole.statement"
                class="d-flex align-items-center card p-2 cursor-pointer"
                @click="
                    $router.push({
                      path: '/wallet/statement',
                      query: { bankholderid: walletAccount.bankholderID },
                    })
                  "
                >
                  <svg-icon
                    icon-class="statement"
                    style="vertical-align: middle"
                  />
                  <span class="nav-link-text">Statment</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 mb-4 mb-xl-0 pl-xl-0 pr-xl-0 ">
          <div class="card h-100">
            <div class="card-header mx-4 p-3 text-center">
              <div
                class="
                  icon icon-shape icon-lg
                  bg-gradient-primary
                  shadow
                  text-center text-white
                "
              >
                {{ currency }}
              </div>
            </div>
            <div v-if="accessRole.balance" class="card-body pt-0 p-3 text-center pl-xl-0 pr-xl-0">
              <h6 class="text-center mb-0">Balance</h6>
              <h5 class="mb-1 text-xl">
                <span v-if="loadingBalance"
                  ><i class="el-icon-loading"></i></span
                ><span v-if="!loadingBalance && balance">{{
                  balance.balance
                }}</span>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-xl-5 mb-4 mb-xl-0">
          <div class="card bg-gradient-gray-dark h-100">
            <div
              class="overflow-hidden position-relative"
              style="min-height: 150px"
              v-loading="loadingWallet"
            >
              <div class="p-0 pl-3 pt-3 pr-3">
                <h3 class="text-white">{{ customername }}</h3>
                <h4 class="text-white">{{ membercode }}</h4>
              </div>

              <div
                v-if="walletAccount"
                class="card-body p-0 pl-3 pt-1 pr-3 pb-3"
                style="font-size: 14px"
              >
                <div v-if="upgradePlus" class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center text-white">
                    Account name
                  </div>
                  <div class="d-flex align-items-center text-white">
                    {{ walletAccount.accountname }}
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center text-white">
                    {{ getLabelAccountNumber(walletAccount.currency) }}
                  </div>
                  <div class="d-flex align-items-center text-white">
                    {{ getAccount() }}
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center text-white">
                    {{ getLabelSortCode(walletAccount.currency) }}
                  </div>
                  <div class="d-flex align-items-center text-white">
                    {{ getSortcode() }}
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center text-white">
                    Reference
                  </div>
                  <div class="d-flex align-items-center text-white">
                    {{ getReference(walletAccount.bankholderID) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="accessRole.statement" class="col-md-12 mb-4 mb-xl-0 mt-0 mt-xl-4">
          <div class="card">
            <div class="card-header pb-0 p-3">
              <h4 class="mb-0">Statement today</h4>
              <div class="position-absolute top-2 right-2 pt-1 pr-1">
              <el-button
              
                type="text"
                @click="$router.push({ path: '/wallet/statement?bankholderid='+walletAccount.bankholderID })"
              >
                Show all statement
              </el-button>
        </div>
            </div>
            <div class="card-body p-3">
              <el-table
                :data="tableData"
                row-key="uid"
                border
                style="width: 100%"
                size="mini"
                v-loading="loadingStatement"
              >
                <el-table-column
                  prop="date"
                  label="Date"
                  header-align="center"
                  align="center"
                  width="160"
                ></el-table-column>
                <el-table-column
                  prop="description"
                  min-width="200"
                  class-name="line-break"
                  label="Description"
                ></el-table-column>
                <el-table-column
                  prop="credit"
                  label="Credit"
                  header-align="center"
                  align="right"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="debit"
                  label="Debit"
                  header-align="center"
                  align="right"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="balance"
                  label="Balance"
                  header-align="center"
                  align="right"
                  width="100"
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils";
import WalletApi from "@/api/wallet/wallet";
import BankApi from "@/api/bankaccount";
export default {
  computed: {
    customername() {
      return this.$store.getters.name;
    },
    membercode() {
      return this.$store.getters.membercode;
    },
    accessRole() {
      return this.$store.getters.accessrole;
    },
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
  },
  data() {
    return {
      loadingStatement: false,
      loadingWallet: false,
      loadingBalance: false,
      balance: null,
      currency: this.$route.params.currency,
      bankholderid: this.$route.params.bankholderid,
      walletAccount: null,
      tableData: [],
    };
  },
  methods: {
    setCurrencyCompare() {
      if (this.currency) {
        let toCurrency = this.currency.toUpperCase() == "THB" ? "GBP" : "THB";
        this.$parent.$refs.comparecurrency.formData.to = toCurrency;
        this.$parent.$refs.comparecurrency.formData.from = this.currency;
        this.$parent.$refs.comparecurrency.getRate();
      }
    },
    getWalletActive() {
      this.loadingWallet = true;
      WalletApi.getCurrencyActiveList().then(({ result, data }) => {
        this.loadingWallet = false;
        if (result) {
          let wallet = Utils.findObject(
            data,
            this.bankholderid,
            "bankholderID"
          );
          this.walletAccount = wallet;
        }
      });
    },
    getLabelAccountNumber(currency) {
      return ["GBP", "THB"].includes(currency.toUpperCase())
        ? "Account Number"
        : "IBAN";
    },
    getLabelSortCode(currency) {
      return currency.toUpperCase() == "GBP" ? "Sort code" : "BIC";
    },
    getReference(bankholderid) {
      return `TP${Number(bankholderid.substring(bankholderid.length - 6))}`;
    },
    getBalance(bankholderid) {
      this.loadingBalance = true;
      BankApi.getAccountBalance(bankholderid).then(({ data }) => {
        this.loadingBalance = false;
        this.balance = data;
      });
    },

    getStatement(bankholderid) {
      this.loadingStatement = true;

      let query = {
        startdate: Utils.getCurrentDate(),
        enddate: Utils.getCurrentDate(),
      };

      WalletApi.getStatement(bankholderid, query).then(({ result, data }) => {
        this.loadingStatement = false;
        if (result) {
          this.tableData = data["statement"];
        }
      });
    },
    getAccount() {
      return this.walletAccount.domestic_account_display && this.walletAccount.type === 'virtual' ? this.walletAccount.domestic_accountnumber :  this.walletAccount.account
    },
    getSortcode() {
      return this.walletAccount.domestic_account_display && this.walletAccount.type === 'virtual' ? this.walletAccount.domestic_sortcode :  this.walletAccount.sortcode
    },
  },
  mounted() {
    this.setCurrencyCompare();
    this.getWalletActive();
    if(this.accessRole.balance)this.getBalance(this.bankholderid);
    if(this.accessRole.statement) this.getStatement(this.bankholderid);
  },
};
</script>

<style lang="scss">
.wallet-account-currency {
  .nav-link-text {
    font-size: 12px;
  }
  .svg-icon {
    font-size: 14px;
    margin-right: 0;
  }
}
</style>